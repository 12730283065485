<template>
  <div>
    <b-overlay :show="!appData || isLoading">
      <b-card>
        <div v-if="userData && Object.keys(userData).length > 0">
          <h2 class="mb-2">{{ $t("application_data") }}</h2>
          <b-row>
            <b-col
              v-for="(value, key) in memberData"
              :key="key"
              cols="12"
              xl="6"
            >
              <b-row>
                <b-col cols="6">
                  <h4>
                    <feather-icon icon="CheckIcon" class="mr-2" />
                    {{ $t(key) || key }}:
                  </h4>
                </b-col>
                <b-col cols="6">
                  <p v-if="value" class="mx-2">
                    {{ value["name_ar"] || value }}
                  </p>
                  <p v-else class="mx-2">
                    {{ "-" }}
                  </p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <hr />
          <h2 class="mb-2">{{ $t("basic_data") }}</h2>
          <b-row>
            <b-col v-for="(value, key) in userData" :key="key" cols="12" xl="6">
              <b-row>
                <b-col cols="5">
                  <h4>
                    <feather-icon icon="CheckIcon" class="mr-2" />
                    {{ $t(key) || key }}:
                  </h4>
                </b-col>
                <b-col cols="7">
                  <p v-if="value" class="mx-2">
                    {{ value["name_ar"] || value }}
                  </p>
                  <p v-else class="mx-2">
                    {{ "-" }}
                  </p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <hr />
          <h2 class="mb-2">{{ $t("questions_and_answers") }}</h2>
          <article
            v-if="projectDetails && Object.keys(projectDetails).length > 0"
          >
            <section
              v-for="(value, key, index) in projectDetails"
              :key="key"
              cols="12"
              xl="6"
            >
              <hr v-if="index !== 0" />
              <h4>
                <feather-icon icon="CheckIcon" class="mr-2" />
                {{ $t(`${key}_question`) || key }}:
              </h4>
              <p v-if="value" class="mx-2">
                {{ value["name_ar"] || value }}
              </p>
              <p v-else class="mx-2">
                {{ "-" }}
              </p>
            </section>
          </article>
          <div v-if="teamMembers && teamMembers.length > 0">
            <hr />

            <h2 class="mb-2">{{ $t("team_members") }}</h2>
            <div class="w-100 overflow-auto">
              <b-table striped hover :items="teamMembers" :fields="tableFields">
                <template #cell(created_at)="data">
                  {{
                    data.item.created_at
                      ? formatDate(data.item.created_at)
                      : "-"
                  }}
                </template>
                <template #cell(updated_at)="data">
                  {{
                    data.item.updated_at
                      ? formatDate(data.item.updated_at)
                      : "-"
                  }}
                </template>
              </b-table>
            </div>
          </div>

          <div
            v-if="projectAttachments && projectAttachments.length > 0"
            class="mt-3"
          >
            <hr />

            <h2 class="mb-2">{{ $t("project_attachments") }}</h2>
            <div class="w-100 overflow-auto">
              <b-table
                striped
                hover
                :items="projectAttachments"
                :fields="projectAttachmentsTableFields"
              >
                <template #cell(attachments)="data">
                  <ImagePreviewer
                    :file="data.item.attachments"
                    :show-actions="true"
                    :customFileName="`${projectDetails.project_title}-${data.item.name}`"
                  />
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BOverlay, BTable } from "bootstrap-vue";
import ImagePreviewer from "@/components/UI/ImagePreviewer.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BOverlay,
    BTable,
    ImagePreviewer,
  },
  props: {},
  data() {
    return {
      memberData: {},
      userData: {},
      projectDetails: {},
      teamMembers: [],
      projectAttachments: [],
      AppId: null,
      appType: "",
      projectAttachmentsTableFields: [
        { key: "attachments", label: this.$t("attachments") },
        { key: "name", label: this.$t("name") },
        { key: "description", label: this.$t("description") },
      ],
      tableFields: [
        { key: "id", label: this.$t("id") },
        { key: "name", label: this.$t("name") },
        { key: "national_id", label: this.$t("national_id") },
        {
          key: "phone_number",
          label: this.$t("phone_number"),
        },
        {
          key: "profession_license_number",
          label: this.$t("profession_license_number"),
        },
        {
          key: "participation_percentage",
          label: this.$t("participation_percentage"),
        },
        { key: "created_at", label: this.$t("created_at") },
        { key: "updated_at", label: this.$t("updated_at") },
        { key: "department", label: this.$t("department") },
        {
          key: "application_id",
          label: this.$t("application_id"),
        },
        { key: "job", label: this.$t("job") },
      ],
    };
  },
  computed: {
    appData() {
      return this.$store.state[`application_${this.appType}`]?.["data"];
    },
    isLoading() {
      return this.$store.state[`application_${this.appType}`]?.isLoading
        .fetching;
    },
  },
  mounted() {
    this.$route.params.id
      ? this.getApplication(
          this.$route.params.id,
          this.$route.params.type || this.$route.query.type
        )
      : this.$router.push("/applications");
  },
  watch: {
    appData(val) {
      if (!val) return;
      const application = val[0];

      if (!application) return;

      this.memberData["name"] = application.member.name;
      this.memberData["email"] = application.member.email;
      this.memberData["phone_number"] = application.phone_number;
      this.memberData["application_number"] =
        application.application_number || application.id;
      this.memberData["hospital_name"] = application.hospital_name;
      this.memberData["hospital_direction"] = application.hospital_direction;
      this.memberData["application_status"] = this.$t(application.status);
      if (application.status === "incomplete application") {
        this.memberData["incomplete_application_reason"] =
          application.incomplete_application_reason;
      }

      if (application.status === "rejected") {
        this.memberData["rejection_reason"] =
          application.rejection_reason?.name_ar;
        this.memberData["rejection_reason_notes"] =
          application.rejection_reason_notes;
      }

      if (this.appType === "individual") {
        this.memberData["governorate"] = application.governorate;

        // map data for userData
        this.userData["candidate_name"] = application.candidate_name;
        this.userData["national_id"] = application.national_id;
        this.userData["job_title"] = application.job_title;
        this.userData["department"] = application.department;
        this.userData["profession_license_number"] =
          application.profession_license_number;
        this.userData["department_management"] =
          application.department_management;
        this.userData["email"] = application.email;
        this.userData["created_at"] = application.created_at
          ? this.formatDate(application.created_at)
          : "";

        // map data for projectDetails
        this.projectDetails["nomination_full_description"] =
          application.nomination_full_description;
        this.projectDetails["commitment_situation"] =
          application.commitment_situation;
        this.projectDetails["communication_situation"] =
          application.communication_situation;
        this.projectDetails["situation_for_kindness"] =
          application.situation_for_kindness;
        this.projectDetails["situation_for_educating_patient"] =
          application.situation_for_educating_patient;
        this.projectDetails["situation_for_contribution"] =
          application.situation_for_contribution;
        this.projectDetails["situation_for_innovative_solutions"] =
          application.situation_for_innovative_solutions;
        this.projectDetails["has_nominated_before"] =
          application.has_nominated_before;
      }

      if (this.appType === "team") {
        this.memberData["hospital_governorate"] =
          application.hospital_governorate;
        this.memberData["hospital_address"] = application.hospital_address;

        this.userData["contact_name"] = application.contact_name;
        this.userData["contact_phone"] = application.contact_phone;
        this.userData["contact_email"] = application.contact_email;

        // map data for projectDetails
        this.projectDetails["project_title"] = application.project_title;
        this.projectDetails["project_start_date"] =
          application.project_start_date
            ? this.formatDate(application.project_start_date)
            : "";
        this.projectDetails["project_end_date"] = application.project_end_date
          ? this.formatDate(application.project_end_date)
          : "";
        this.projectDetails["project_impact"] = application.project_impact;
        this.projectDetails["project_implementation"] =
          application.project_implementation;
        this.projectDetails["scope_of_implementation"] =
          application.scope_of_implementation;
        this.projectDetails["project_program_objectives"] =
          application.project_program_objectives;
        this.projectDetails["project_benefited"] =
          application.project_benefited;
        this.projectDetails["brief_description_of_the_project"] =
          application.brief_description_of_the_project;
        this.projectDetails["challenges_during_implementation"] =
          application.challenges_during_implementation;
        this.projectDetails["challenges_you_faced"] =
          application.challenges_you_faced;
        this.projectDetails["results_achieved"] = application.results_achieved;

        this.teamMembers = application.team_members;

        this.projectAttachments = application.project_attachments;
      }
    },
  },
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleString();
    },
    getApplication(appNumber, type) {
      if (!type || !appNumber) return;
      this.AppId = appNumber;
      this.appType = type;
      this.$store.dispatch(`application_${type}/fetchData`, {
        application_number: appNumber,
      });
    },
  },
};
</script>